// geoDataUtils.js
import axios from "axios";

export const fetchObjectData = async (lineData, transformerCheckedIds, setFilteredObject) => {
  try {
    // Fetch data from the WFS service
    const response = await axios.get('https://geoserver.fornaxenergytech.com/geoserver/wfs', {
      params: {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        typeName: 'Gis_Gujarat:location_atlas',
        outputFormat: 'application/json',
      },
    });

    // Assuming the response.data is a FeatureCollection with a features array
    const featureCollection = response.data.features;

    const projectids = lineData.features
      .filter((feature) => transformerCheckedIds.includes(feature.properties.substation_id))
      .map((feature) => feature.properties.project_id);

    // Filter the data based on projectids
    const filterData = featureCollection.filter((item) =>
      projectids.includes(item.properties.project_id)
    );

    // Update state with filtered data
    setFilteredObject(filterData);

    console.log("Filtered Data", filterData);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
