// mapUtils.js
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';

export const exportMapAsPDF = (mapRef, setMapLoading, setSuccess, lineData) => {
  const mapElement = mapRef.current._container;
  setMapLoading(true);
  domtoimage.toPng(mapElement)
    .then((dataUrl) => {
      const imgData = dataUrl;

      // Create a new jsPDF instance
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: 'a4' // Use standard A4 size
      });

      // Define the margins, borders, and dimensions
      const marginTop = 30;
      const marginLeft = 20;
      const borderWidth = 2;
      const headerHeight = 30;
      const logoWidth = 30;
      const logoHeight = 20;
      const legendWidth = 50;
      const legendHeight = 80;
      const legendMargin = 10;

      // Get page dimensions
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Aspect ratio calculation
      const aspectRatio = mapElement.offsetWidth / mapElement.offsetHeight;

      // Set maximum map size with margins and legend space
      let imgWidth = pageWidth - legendWidth - legendMargin + 40;
      let imgHeight = imgWidth / aspectRatio + 50;

      if (imgHeight > pageHeight - marginTop - headerHeight - marginLeft * 2) {
        imgHeight = pageHeight - marginTop - headerHeight - marginLeft * 2;
        imgWidth = imgHeight * aspectRatio + 40;
      }

      // Ensure the image doesn't exceed page dimensions
      if (imgWidth > pageWidth - marginLeft * 2) {
        imgWidth = pageWidth - marginLeft * 2;
        imgHeight = imgWidth / aspectRatio + 40;
      }

      // Calculate offsets for positioning
      const xOffset = marginLeft;
      const yOffset = marginTop + headerHeight;

      // Header background
      pdf.setFillColor(240, 240, 240);
      pdf.rect(0, 0, pageWidth, headerHeight, 'F');

      // Header text (centered)
      pdf.setFontSize(16);
      pdf.setTextColor(0, 0, 0);
      pdf.text('Area Map', pageWidth / 2, headerHeight / 2 + 5, { align: 'center' });

      // Add logo (ensure correct base64 format or load image beforehand)
      const logo = 'IntelliGIS_Fornax.png'; // Insert base64 encoded image here if needed
      if (logo) {
        pdf.addImage(logo, 'PNG', pageWidth - logoWidth - marginLeft, 5, logoWidth, logoHeight);
      }

      // Add map image
      pdf.addImage(
        imgData,
        'PNG',
        xOffset,
        yOffset,
        imgWidth,
        imgHeight
      );

      // Draw legend box
      const legendX = xOffset + imgWidth - 100;
      const legendY = yOffset;
      pdf.setFillColor(255, 255, 255);
      pdf.rect(legendX, legendY, legendWidth, legendHeight, 'F');

      // Add legend text
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      pdf.text('Legend', legendX, legendY + 10);
      pdf.text('Area :', legendX + 10, legendY + 30);

      pdf.text(`• Point:  ${5 + 43}`, legendX + 5, legendY + 45);

      pdf.text("- Line", legendX + 10, legendY + 60);
      pdf.text("Polygon", legendX + 5, legendY + 75);

      // Draw footer
      const borderYBottom = pageHeight - 20;
      pdf.setLineWidth(1);
      pdf.line(marginLeft, borderYBottom, pageWidth - marginLeft, borderYBottom);

      // Footer text with date
      pdf.setFontSize(10);
      const footerText = 'Generated on: ' + new Date().toLocaleString();
      const textWidth = pdf.getTextWidth(footerText);
      pdf.text(footerText, pageWidth - marginLeft - textWidth, pageHeight - 10);

      // Save the PDF
      pdf.save('leaflet-map.pdf');
      setMapLoading(false);
      setSuccess(true);
    })
    .catch((error) => {
      setMapLoading(false);
      setSuccess(false);
      console.error('Error capturing the map image:', error);
    });
};
