import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.markercluster";
import PropTypes from "prop-types";

const MarkerCluster = ({ data, renderPopup }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !data?.features?.length) return;

    // Initialize a marker cluster group
    const markers = L.markerClusterGroup({
      chunkedLoading: true, // For smoother performance on large datasets
      animate: true, // Enable animation
      animateAddingMarkers: true, // Enable animation for adding markers
      spiderfyOnMaxZoom: false, // Disable spiderfying at max zoom
    });

    // Add GeoJSON points to the cluster group
    data.features.forEach((feature) => {
      const [lng, lat] = feature.geometry.coordinates;

      // Ensure the 'name' is always on top
      const name = feature.properties.name;

      // Create the marker
      const marker = L.marker([lat, lng]);

      // Bind the tooltip to show the name
      marker.bindTooltip(name, {
        permanent: true, // Ensures the label is always visible
        direction: "top", // Position the label above the marker
        className: "custom-label", // Optional: Add custom styles
        offset: [-15, 0], // Adjust position of the label
      });

      // Bind a popup with a custom div when the marker is clicked
      const popupContent = `
        <div style="width: 250px; height: 250px; padding:25px;" >
            <h5  style="text-align:center; width:100%; padding-left:35px;">
          ${name}
            </h5>
            <div style="display:flex; justify-content:space-around;  ">
            <div> <Traingle/>></div>
            <div> Transformer</div>
            <div> ss</div>
            </div>
        </div>
      `;
      marker.bindPopup(popupContent, {
        autoClose: false, // Keep the popup open until manually closed
      });

      // Listen for the popup open event to hide the tooltip
      marker.on("popupopen", () => {
        marker.unbindTooltip(); // Hide the tooltip when the popup is open
      });

      // Listen for the popup close event to show the tooltip again
      marker.on("popupclose", () => {
        marker.bindTooltip(name, {
          permanent: true, // Ensures the label is always visible again
          direction: "top", // Position the label above the marker
          className: "custom-label", // Optional: Add custom styles
          offset: [-15, 0], // Adjust position of the label
        });
      });

      // Add the marker to the cluster group
      markers.addLayer(marker);
    });

    // Add the cluster group to the map
    map.addLayer(markers);

    // Smooth zoom on cluster click
    markers.on("clusterclick", (event) => {
      const bounds = event.layer.getBounds();
      map.flyToBounds(bounds, {
        padding: [50, 50], // Padding around the bounds
        duration: 1.5, // Animation duration in seconds
        easeLinearity: 0.25, // Easing function for a smooth transition
      });
    });

    // Cleanup function to remove the cluster group from the map
    return () => {
      map.removeLayer(markers);
    };
  }, [map, data, renderPopup]);

  return null;
};

MarkerCluster.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        geometry: PropTypes.shape({
          type: PropTypes.string.isRequired,
          coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
        }).isRequired,
        properties: PropTypes.object.isRequired,
      })
    ).isRequired,
  }).isRequired,
  renderPopup: PropTypes.func, // Optional custom popup renderer
};

MarkerCluster.defaultProps = {
  renderPopup: null,
};

export default MarkerCluster;
